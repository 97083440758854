import React, { Component } from 'react';
import {Link} from "gatsby"
import story1 from '../../img/story1.png'
import story2 from '../../img/story2.png'
import arrow from '../../img/icons/icon-arrow-right-red.svg'
import logo from '../../img/martal.png'
import foto from '../../img/2.png'


export default class Stories extends Component{
    render(){
        console.log(this.props.data)
        var data = this.props.data;
        
        return(
            <section id="about">
            <div className="aboutContainer">
                <div className="AboutTextContainer">
                    <div className="horizontalLine"></div>
                    <div className="title"><h1>{data.titel_sectie_4.text}</h1></div>
                    <div className="text">{data.tekst_sectie_4.text}
                    <div className="storyLink">
                      <Link to={"/over-ons"}> Meer over ons ></Link>
                    </div>
                    </div>

                </div>
                <div className="overflow">
                   <img src={data.image_sectie_4.url} />
                </div>
            </div>
        </section>
        );
    }
}