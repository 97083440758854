import React, { Component } from 'react';
import Link from "gatsby-link"
import story1 from './img/story1.png'
import story2 from './img/story2.png'
import arrow from './img/arrow.png'
import logo from './img/logo_martal.svg'
import logofsc from './img/icons/logo-fsc.png'
import foto from './img/2.png'

class Footer extends Component {
    render() {
      return (
        <footer>
            <div className="footerMenuContainer">
                <div className="menuLeft">
                    <div id="logoContainer">
                        <img className="logo" src={logo} alt="Martal logo" />
                    </div>
                    <div className="menuContainer">
                        <ul className="menuLinks">
                            <li><Link to={"/"+"nl-be/product"}>Producten</Link></li>
                            <li><Link to={"/"+"nl-be/approach"}>Onze aanpak</Link></li>
                            <li><Link to={"/"+"nl-be/about"}>Over ons</Link></li>
                            <li><Link to={"/"+"nl-be/contact"}>Contact</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="menuRight">
                    <img src={logofsc}/>
                </div>
            </div>
            <div className="copyright">
                <span>Copyright 2017</span>
            </div>
        </footer>
      );
    }
  }

  export default Footer;
