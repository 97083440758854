import React, { Component } from 'react';
import Link from "gatsby-link"

import story1 from '../../img/story1.png'
import story2 from '../../img/story2.png'
import arrow from '../../img/icons/icon-arrow-right-red.svg'
import logo from '../../img/icons/icon-quality.png'
export default class Quality extends Component{
    render(){
        var data = this.props.data;
        console.log("Quality", data)
        return(
            
            <div id="QualityWork">

                <div className="title tk-acumin-pro">
                    <h1>{data.titel_sectie_2.text}</h1>
                </div>
                <div className="subText">
                {data.text_sectie_2.text}
                </div>
            </div>
        );}
        }