import React, { Component } from 'react';
import Link from "gatsby-link"
import overview from '../../img/icons/icon-overview.svg'
import story2 from '../../img/story2.png'
import arrow from '../../img/icons/icon-arrow-right.svg'
import logo from '../../img/martal.png'
import foto from '../../img/2.png'
import hardwood from '../../img/hardwood.jpg'
import massive from '../../img/massive-panels.jpg'

export default class Stories extends Component{

    render(){
        console.log("stories", this.props.data)
        var data = this.props.data;
        var products = this.props.data.body[1].items

        var bg = data.image_sectie_4.url;
        var divStyle = {
            backgroundImage: 'linear-gradient( rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('+bg+')'
          };

        return(
            <section id="products" style={divStyle}>
                <div className="sectionTitle">
                    <h1>{data.titel_sectie_3.text}</h1>
                </div>
                <div className="sectionDescriptionContainer">
                    <div className="horizontalLine">

                    </div>
                    <div className="sectionDescription">
                    {data.tekst_sectie_3.text}

                    </div>
                    <div className="allProductsContainer">
                        <img src={overview}/>
                        <div>
                        <Link to={"/"} style={{color: "white"}}>{data.tekst_link_sectie_3.text} </Link>
                        </div>
                        <img className="arrow" src={arrow}/>
                    </div>
                </div>
                <div className="productimages">
                    <div id="hardwoordContainer" className="productContainer">
                    <Link to={"/"}><img className="product" alt={""} src={products[0].image.url}/></Link>
                        <div><Link to={"/"}>{products[0].titel.text}</Link></div>
                    </div>
                    <div id="massiveContainer" className="productContainer">
                    <Link to={"/"}><img className="product" alt={""} src={products[1].image.url}/></Link>
                        <div><Link to={"/"}>{products[1].titel.text}</Link></div>
                    </div>
                    <div id="accoyaContainer" className="productContainer">
                    <Link to={"/"}><img className="product" alt={""} src={products[2].image.url}/></Link>
                        <div><Link to={"/"}>{products[2].titel.text}</Link></div>
                    </div>
                </div>
                <div className="btnViewAllProducts">
                <Link to={"/"} style={{color: "white"}}>{data.tekst_knop_sectie_3.text} </Link>
                </div>
            </section>
        );
    }
}
