//import "../../css/video.css"; // or import scss
import React, { Component } from 'react';
import { Player, ControlBar, VolumeMenuButton } from 'video-react';
import bg from '../../img/martal-hardwoord-belgium.jpg'
import playArrow from '../../img/playarrow.svg'

var button = {
  'nl-be': 'Bekijk de volledige video',
  'en-gb': 'Watch the entire video',
  'fr-be': 'Regardez la video en entier'
}

export default class Video extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
          source: 'bunnyMovie',
          player: null
        };

        this.pause = this.pause.bind(this);
      }

      componentDidMount() {
        // subscribe state change
        this.refs.player.subscribeToStateChange(this.handleStateChange.bind(this));
      }

      handleStateChange(state, prevState) {
        // copy player state to this component's state
        this.setState({
          player: state
        });
      }

      pause() {
        this.refs.player.pause();
      }

    render() {
      var hide="hide"
      if(this.state.player!=null && this.state.player.currentTime>27.5){
        this.refs.player.pause();
        hide="";
      }
      return (
        <div className="homevideo" id="videocontainer">
            <div className={hide} id="blur"></div>
            <div className={hide} onClick={this.props.toggleFullVideo} id="fullVideoTrigger">
            <div id="text"><div id="playbtnText">{button[this.props.lang]}</div><div id="arrowIcon"><img src={playArrow}/></div></div>
            </div>
            <Player ref="player" poster={this.props.data.data.image_sectie_4.url} autoPlay={true} loop={false} muted={true} playsInline={true}>
                <ControlBar autoHide={true} disableDefaultControls={true}>
                </ControlBar>
            </Player>
        </div>
      );
    }
  }
