//import "../../css/video.css"; // or import scss
import React, { Component } from 'react';
import { Player, ControlBar, VolumeMenuButton } from 'video-react';
import bg from '../../img/bgmartalvideo.png'

export default class Video extends Component {
    constructor(props) {
        super(props);
      }
    render() {
      return (
        <div id="videocontainer">
                <Player autoPlay={true} loop={false} muted={true}>
                <source src={this.props.url}/>
                <ControlBar autoHide={true} disableDefaultControls={true}>
                </ControlBar>
            </Player>
        </div>
      );
    }
  }