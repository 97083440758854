import React, { Component } from "react";
import Video from "../layouts/Home/Video.js";
import Home from "../layouts/Home/Home.js";
import { Helmet } from "react-helmet";
import DefaultLayout from "../layouts/defaultLayout";

//import "../styles/Home.module.css"

export default class HomePageTemplate extends Component {
  constructor(props) {
    super(props);
    console.log("homelala", this);

    /* //var path = this.props.location.pathname.lastIndexOf("/")
    var slug = this.props.location.pathname.substr(path+1)

    if(slug!="fr-be" || slug!="en-gb"){
        slug="nl-be"
    }*/

    var data = {};
    data = this.props.data.allPrismicHome.edges[0].node;

    this.state = {
      data: data,
      lang: "nl-be",
    };
  }

  componentWillMount() {}

  changeLanguage(language) {
    console.log(this.props);
    //this.props.changeLanguage(language);
    //
  }

  render() {
    console.log("state homepage", this.state);
    return (
      <DefaultLayout data={this.props.data.allPrismicMenu.edges[0].node.data}>
        <Helmet>
          <title>{this.state.data.data.meta_title.text}</title>
          <meta
            name="description"
            content={this.state.data.data.intro_text.text}
          />
        </Helmet>
        <Home lang={this.state.lang} data={this.state.data} />
      </DefaultLayout>
    );
  }
}

export const pageQuery = graphql`
  query home1 {
    allPrismicMenu {
      edges {
        node {
          id
          slugs
          lang
          data {
            logo {
              
              url
            }
            telefoonnummer {
              html
              text
            }
            contact {
              html
              text
            }
            diensten {
              html
              text
            }
            over_ons {
              html
              text
            }
            jobs {
              html
              text
            }
            afspraak {
              html
              text
            }
            body {
              ... on PrismicMenuBodyDiensten {
                items {
                  titel {
                    html
                    text
                  }
                  link {
                    html
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicHome {
      edges {
        node {
          id
          slugs
          lang
          data {
            meta_title {
              html
              text
            }
            intro_text {
              html
              text
            }
            intro_titel {
              html
              text
            }
            text_knop {
              html
              text
            }
            tekst_link_1 {
              html
              text
            }
            tekst_link_2 {
              html
              text
            }
            subtekst_link_1 {
              html
              text
            }
            subtekst_link_2 {
              html
              text
            }
            titel_sectie_2 {
              html
              text
            }
            text_sectie_2 {
              html
              text
            }
            titel_sectie_3 {
              html
              text
            }
            tekst_sectie_3 {
              html
              text
            }
            tekst_knop_sectie_3 {
              html
              text
            }
            tekst_link_sectie_3 {
              html
              text
            }
            tekst_sectie_4 {
              html
              text
            }
            titel_sectie_4 {
              html
              text
            }
            titel_zolderkamer {
              html
              text
            }
            text_zolderkamer {
              html
              text
            }
            link_zolderkamer {
              html
              text
            }
            hero_image {
              url
            }
            section2_image {
              url
            }
            image_sectie_4 {
              
              url
            }
            image_zolderkamer {
              
              url
            }
            body {
              __typename
              ... on PrismicHomeBodySectie2 {
                slice_type
                items {
                  image {
                    url
                  }
                  image2 {
                    url
                  }
                  titel {
                    html
                    text
                  }
                  tekst {
                    html
                    text
                  }
                }
              }
              ... on PrismicHomeBodySectie3 {
                items {
                  image {
                    
                    url
                  }
                  titel {
                    html
                    text
                  }
                  link {
                    html
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
