import React, { Component } from 'react';
//import '../../css/Home.css';
//import '../../css/carousel.css';
import foto from '../../img/2.png'
import story1 from '../../img/story1.png'
import Video from './Video.js'
import HomeVideo from './HomeVideo.js'
import story2 from '../../img/story2.png'
import arrow from '../../img/arrow.png'
import logo from '../../img/martal.png'
import forte from '../../img/forte_logo.png'
import Best from './Best.js';
import Quality from './Quality.js';
import Stories from './Stories.js';
import OurProducts from './OurProducts.js';
import AboutUs from './AboutUs.js';
import FullVideo from './FullVideo.js';
import Footer from "../../Footer.js"
//import "../../styles/Home.module.css"
import videobg from '../../img/bgmartalvideo.png'
//import '../../css/Footer.css';
import {Link} from "gatsby"


export default class Home extends Component {

  constructor(props) {
    // Required to call original constructor
    super(props);
    console.log(props)
    // Props are now accessible from here
    this.state = {
        showFullVideo: false
      }
      this.toggleFullVideo = this.toggleFullVideo.bind(this);
      console.log(props)
}
  
  toggleFullVideo() {
    console.log("pressed");
    this.setState({showFullVideo: !this.state.showFullVideo});
  }
  

  render() {
    var data = this.props.data.data;
    var img = this.props.data.data.image_sectie_4.url
    var best = this.props.data.data.body[0]
    var quality = this.props.data.data.body[1]
    var stories = this.props.data.data.body[1]
    var products = this.props.data.data.body[2]
    var about = this.props.data.data.body[3]

    //var video = data.video.url
    return (
      <div className="paddingtop heroImage" style={{backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("+ this.props.data.data.hero_image.url +")"}}>
        <Best lang={""} data={data}/>
        <Quality lang={""} data={data}/>
        <Stories lang={""} data={data}/>
        <section id="about" className="zolderkamer">
            <div className="aboutContainer">
                <div className="overflow">
                   <img src={data.image_zolderkamer.url} />
                </div>
                <div className="AboutTextContainer">
                    <div className="horizontalLine"></div>
                    <div className="title"><h1>{data.titel_zolderkamer.text}</h1></div>
                    <div className="text">{data.text_zolderkamer.text}
                    <div className="storyLink">
                      <a href={data.link_zolderkamer.text} target="_blank"> Logopedie Zolderkamer ></a>
                    </div>
                    </div>

                </div>
            </div>
        </section>
        <AboutUs lang={""} data={data}/>
        <footer>
            <div className="footerMenuContainer">
                <div className="menuLeft">
                    <div id="logoContainer">
                    <img className="logo" src={forte}/>
                    </div>
                    <div className="menuContainer">
                        <ul className="menuLinks">
                          <li><a href="/specialisaties">Specialisaties</a></li>
                          <li><a href="/over-ons">Over ons</a></li>
                          <li><a href="/over-ons#jobs">Jobs</a></li>
                          <li><a href="/contact">Contact</a></li>
                        </ul>
                    </div>
                </div>
                <div className="menuRight">
                    
                </div>
            </div>
            <div className="copyright">
            <div id="gafaslink">
                Website by
                <a href="https://www.gafas.be"> Gafas</a>
                </div>
            </div>
        </footer>
        </div>
    );
  }
}

/*
            <Best lang={this.props.lang} data={best}/>
            <Quality lang={this.props.lang} data={quality}/>
            <Stories lang={this.props.lang} data={stories}/>
            <OurProducts lang={this.props.lang} data={products}/>
            <AboutUs lang={this.props.lang} data={about}/>
*/