//import "../../css/video.css"; // or import scss
import React, { Component } from 'react';
import { Player, ControlBar, VolumeMenuButton } from 'video-react';
import close from '../../img/close.png'
import YouTube from 'react-youtube';

export default class FullVideo extends Component {

    constructor(props) {
        // Required to call original constructor
        super(props);
        console.log(props)
        // Props are now accessible from here
        this.state = {
            showFullVideo: this.props.show
          }
          this.handleClick = this.handleClick.bind(this);
        }

    handleClick() {
        this.setState({showFullVideo: !this.state.showFullVideo});
        this.state.player.pauseVideo();
      }
      

    render() {

      const opts = {
        height: '720',
        width: '1080',
        playerVars: { // https://developers.google.com/youtube/player_parameters
          autoplay: 0
        }
      };

      JSON.stringify(this.state.player, null, 2)
      console.log(this.props.show)
        var hide = "hide";
        if(this.props.show){
            hide="";
        }
      if(hide)
      {
        return(
          <div className={hide} id="overlay">
          </div>
        );
      }
      return (
        <div className={hide} id="overlay">
            <div id="FullVideoContainer">
            <img onClick={this.props.toggleFullVideo} className="closeFullVideo" src={close}/>
            {/*}<iframe width="560" height="80%" src="https://www.youtube.com/embed/6BXZMpQFVEA" frameBorder="0" allowFullScreen></iframe>{*/}
            <YouTube
        videoId="6BXZMpQFVEA"
        opts={opts}
        onReady={this._onReady}
      />
            </div>
        </div>
      );
    }
  }