import React, { Component } from "react";
import { Link } from "gatsby";

import story1 from "../../img/story1.png";
import story2 from "../../img/story2.png";
import arrow from "../../img/icons/icon-arrow-right.svg";
import logo from "../../img/martal.png";
import foto from "../../img/wood-cutting-hands.jpg";

var Carousel = require("react-responsive-carousel").Carousel;

const divStyle = {
  position: "relative",
  flexWrap: "wrap",
  display: "flex"
};

export default class Best extends Component {
  render() {
    var data = this.props.data;
    var story = data.primary;
    var links = data.items;
    console.log("DATA", data);

    return (
      <div style={divStyle}>
        <div id='qualityAndProducts'>
          <div id='qualityContainer' className='submenu tk-acumin-pro'>
            <div>
              <span className='title'>
                <Link to={"specialisaties"}>
                  {data.tekst_link_1.text}
                  <img className='arrow' src={arrow} />
                </Link>
              </span>
              <span className='subtitle'>{data.subtekst_link_1.text}</span>
            </div>
          </div>
          <div id='productsContainer' className='submenu'>
            <div>
              <span className='title'>
                <Link to={"contact"}>
                  {data.tekst_link_2.text}
                  <img className='arrow' src={arrow} />
                </Link>
              </span>
              <span className='subtitle'>{data.subtekst_link_2.text}</span>
            </div>
          </div>
        </div>
        <div id='topStory' className='tk-acumin-pro'>
          <div id='topStorySubContainer'>
            <div className='storyTitle'>
              <h1>{data.intro_titel.text}</h1>
            </div>
            <div className='storyText'>{data.intro_text.text}</div>
            <span className='storyButton tk-acumin-pro'>
              <Link style={{ color: "white" }} to={"over-ons"}>
                {data.text_knop.text}
              </Link>
            </span>
          </div>
        </div>

        <div
          className='imgContainer'
          style={{ backgroundImage: "url(" + data.section2_image.url + ")" }}
        ></div>
      </div>
    );
  }
}
